import React, { lazy, Suspense, useEffect, useState, useCallback, useMemo } from 'react';
import { MapContainer, TileLayer, Marker, GeoJSON, useMap, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import axios from 'axios';
import './App.css';

const API_BASE_URL = 'https://api.mapmynews.com/app.php';

const SideBar = lazy(() => import('./SideBar'));
const Dialog = lazy(() => import('./Dialog'));
const MapTabs = lazy(() => import('./MapTabs'));

const categories = [
  { id: 'all', label: 'All' },
  { id: 'accident', label: 'Accident', icon: '/PNG/PNG/accident.png' },
  { id: 'accident/death', label: 'Accident/Death', icon: '/PNG/PNG/accident_death.png' },
  { id: 'air defence', label: 'Air Defence', icon: '/PNG/PNG/air defence.png' },
  { id: 'airforce', label: 'Air Force', icon: '/PNG/PNG/air force.png' },
  { id: 'business', label: 'Business', icon: '/PNG/PNG/business.png' },
  { id: 'elections', label: 'Elections', icon: '/PNG/PNG/elections.png' },
  { id: 'emergency', label: 'Emergency', icon: '/PNG/PNG/emergency.png'},
  { id: 'entertainment', label: 'Entertainment', icon: '/PNG/PNG/entertainment.png' },
  { id: 'explosion', label: 'Explosion', icon: '/PNG/PNG/explosion.png' },
  { id: 'finance', label: 'Finance', icon: '/PNG/PNG/finance.png' },
  { id: 'financial institution', label: 'Financial Institution', icon: '/PNG/PNG/financial institution.png' },
  { id: 'flight', label: 'Flight', icon: '/PNG/PNG/flight.png' },
  { id: 'general', label: 'General Announcement', icon: '/PNG/PNG/General_ Announcement .png' },
  { id: 'health', label: 'Health', icon: '/PNG/PNG/health.png' },
  { id: 'legal', label: 'Legal', icon: '/PNG/PNG/legal.png' },
  { id: 'military', label: 'Military', icon: '/PNG/PNG/military.png' },
  { id: 'missile', label: 'Missile', icon: '/PNG/PNG/missile.png' },
  { id: 'navy', label: 'Navy', icon: '/PNG/PNG/navy.png' },
  { id: 'navy ship', label: 'Navy Ship', icon: '/PNG/PNG/navyship.png' },
  { id: 'science', label: 'Science', icon: '/PNG/PNG/science.png' },
  { id: 'sports', label: 'Sports', icon: '/PNG/PNG/sports.png' },
  { id: 'technology', label: 'Technology', icon: '/PNG/PNG/technology.png' },
  { id: 'terror', label: 'Terror', icon: '/PNG/PNG/terror.png' },
  { id: 'vessel', label: 'Vessel', icon: '/PNG/PNG/vessel.png' },
  { id: 'warzone', label: 'Warzone', icon: '/PNG/PNG/warzone.png' },
];

const categoryIcons = categories.reduce((icons, category) => {
  if (category.icon) {
    icons[category.id] = L.icon({
      iconUrl: category.icon,
      iconSize: [32, 32],
      iconAnchor: [16, 32],
      popupAnchor: [0, -32]
    });
  }
  return icons;
}, {});

const defaultIcon = L.icon({
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
});

L.Icon.Default.mergeOptions({
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const preloadMapTiles = (centerLat, centerLng, zoom) => {
  const tileUrl = (x, y, z) => `https://c.tile.openstreetmap.org/${z}/${x}/${y}.png`;
  const numTiles = Math.pow(2, zoom);
  const x = Math.floor((centerLng + 180) / 360 * numTiles);
  const y = Math.floor((1 - Math.log(Math.tan(centerLat * Math.PI / 180) + 1 / Math.cos(centerLat * Math.PI / 180)) / Math.PI) / 2 * numTiles);

  for (let i = -1; i <= 1; i++) {
    for (let j = -1; j <= 1; j++) {
      const link = document.createElement('link');
      link.rel = 'preload';
      link.as = 'image';
      link.href = tileUrl(x + i, y + j, zoom);
      document.head.appendChild(link);
    }
  }
};

const LazyTileLayer = React.memo(() => {
  const map = useMapEvents({
    moveend: () => {
      const { lat, lng } = map.getCenter();
      preloadMapTiles(lat, lng, map.getZoom());
    }
  });

  return (
    <TileLayer 
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      maxZoom={19}
      tileSize={256}
      eventHandlers={{
        tileloadstart: (e) => {
          e.tile.setAttribute('loading', 'lazy');
        }
      }}
    />
  );
});

const SetViewOnUser = React.memo(({ coords }) => {
  const map = useMap();
  useEffect(() => {
    if (coords) {
      map.setView(coords, 10);
      preloadMapTiles(coords[0], coords[1], 10);
    }
  }, [coords, map]);
  return null;
});

const MapComponent = () => {
  const api = useMemo(() => axios.create({
    baseURL: API_BASE_URL,
    headers: { 'Content-Type': 'application/json' }
  }), []);

  const [state, setState]  = useState({
    isDialogOpen: false,
    articles: [],
    loading: true,
    error: null,
    selectedArticle: null,
    selectedZoneArticles: [],
    userLocation: null,
    geoJSONData: {},
    zoneColors: {},
  });
  const [activeCategory, setActiveCategory] = useState('all');

  const filteredArticles = useMemo(() => {
    if (activeCategory === 'all') {
      return state.articles;
    }
    return state.articles.filter(article => article.category === activeCategory);
  }, [state.articles, activeCategory]);

  const mapCenter = useMemo(() => [20.593684, 78.96288], []);
  const mapZoom = useMemo(() => 5, []);

  const getMarkerIcon = useCallback((category) => {
    return categoryIcons[category] || defaultIcon;
  }, []);

  const handleCategoryChange = useCallback((category) => {
    setActiveCategory(category);
  }, []);

  const getUserLocation = useCallback(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setState(prev => ({ ...prev, userLocation: [latitude, longitude] }));
        },
        (error) => {
          console.error('Geolocation error:', error);
          setState(prev => ({ ...prev, error: getLocationErrorMessage(error) }));
        },
        { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
      );
    } else {
      setState(prev => ({ ...prev, error: 'Geolocation is not supported by your browser.' }));
    }
  }, []);

  const getLocationErrorMessage = useCallback((error) => {
    const errorMessages = {
      1: 'Location permission denied. Please enable location services.',
      2: 'Location information is unavailable.',
      3: 'The request to get user location timed out.',
    };
    return errorMessages[error.code] || 'Failed to get user location.';
  }, []);

  const getGeoJSONFromZone = useCallback(async (zoneName) => {
    try {
      const response = await axios.get('https://nominatim.openstreetmap.org/search', {
        params: {
          q: zoneName,
          format: 'json',
          polygon_geojson: 1,
        },
      });

      return response.data.length > 0 ? response.data[0].geojson : null;
    } catch (error) {
      console.error('Error fetching geojson:', error);
      return null;
    }
  }, []);

  const fetchArticlesAndGeoJSON = useCallback(async () => {
    try {
      const response = await api.get('');
      const fetchedArticles = response.data;

      const uniqueZones = new Set(fetchedArticles.map((article) => article.zone));
      const geoData = {};
      const colors = {};
      
      await Promise.all(Array.from(uniqueZones).map(async (zone) => {
        if (zone) {
          const geoJSON = await getGeoJSONFromZone(zone);
          if (geoJSON) {
            geoData[zone] = geoJSON;
            const articleWithColor = fetchedArticles.find(article => article.zone === zone && article.color);
            colors[zone] = articleWithColor ? articleWithColor.color : 'blue';
          }
        }
      }));

      setState(prev => ({
        ...prev,
        articles: fetchedArticles,
        geoJSONData: geoData,
        zoneColors: colors,
        loading: false,
      }));
    } catch (error) {
      console.error('Error:', error);
      setState(prev => ({ ...prev, error: 'Failed to fetch articles', loading: false }));
    }
  }, [api, getGeoJSONFromZone]);

  useEffect(() => {
    preloadMapTiles(mapCenter[0], mapCenter[1], mapZoom);
    getUserLocation();
    fetchArticlesAndGeoJSON();
  }, [getUserLocation, fetchArticlesAndGeoJSON, mapCenter, mapZoom]);

  const handleMarkerClick = useCallback((article) => {
    setState(prev => ({ ...prev, selectedArticle: article, isDialogOpen: true }));
  }, []);

  const handleZoneClick = useCallback((zone) => {
    setState(prev => {
      const zoneArticles = prev.articles.filter((article) => article.zone === zone);
      const otherArticles = prev.articles.filter((article) => article.zone !== zone);
      return { ...prev, selectedZoneArticles: [...zoneArticles, ...otherArticles] };
    });
  }, []);

  const handleDialogClose = useCallback(() => {
    setState(prev => ({ ...prev, isDialogOpen: false }));
  }, []);

  if (state.loading) return <p>Loading...</p>;
  if (state.error) return <p>{state.error}</p>;

  const CustomMarker = ({ position, icon, eventHandlers }) => (
    <Marker position={position} icon={icon} eventHandlers={eventHandlers}>
      <div 
        style={{ width: '44px', height: '44px', position: 'absolute', top: '-22px', left: '-22px', cursor: 'pointer' }} 
        role="button" 
        aria-label={`Marker for ${categories} event`}
        tabIndex="0"
      />
    </Marker>
  );
  return (
    <div className="app-container">
      <div className="map-container" role="application" aria-label="Interactive news map">
        <MapContainer center={mapCenter} zoom={mapZoom} style={{ height: '100%', width: '100%' }}>
          <LazyTileLayer />
          {state.userLocation && <SetViewOnUser coords={state.userLocation} />}
          {Object.entries(state.geoJSONData).map(([zone, data]) => (
            <GeoJSON
              key={zone}
              data={data}
              style={{ color: state.zoneColors[zone] || 'red', weight: 2, fillOpacity: 0.2 }}
              eventHandlers={{
                click: () => handleZoneClick(zone),
              }}
            />
          ))}
          {filteredArticles.map((article) => (
            article.latitude && article.longitude && (
              <CustomMarker
                key={article.id}
                position={[article.latitude, article.longitude]}
                icon={getMarkerIcon(article.category)}
                eventHandlers={{ 
                  click: () => handleMarkerClick(article),
                  keypress: (e) => {
                    if (e.originalEvent.key === 'Enter' || e.originalEvent.key === ' ') {
                      handleMarkerClick(article);
                    }
                  }
                }}
              />
            )
          ))}
          <Suspense fallback={<div role="status" aria-live="polite">Loading tabs...</div>}>
            <div className="map-tabs-container">
              <MapTabs activeCategory={activeCategory} onCategoryChange={handleCategoryChange} />
            </div>
          </Suspense>
        </MapContainer>
      </div>
      <Suspense fallback={<div role="status" aria-live="polite">Loading sidebar...</div>}>
        <SideBar
          articles={state.selectedZoneArticles.length > 0 ? state.selectedZoneArticles.filter(article => activeCategory === 'all' || article.category === activeCategory) : filteredArticles}
          selectedArticle={state.selectedArticle?.id}
          onArticleClick={(id) => {
            const article = state.articles.find((a) => a.id === id);
            setState(prev => ({ ...prev, selectedArticle: article, isDialogOpen: true }));
          }}
        />
      </Suspense>
      <Suspense fallback={<div role="status" aria-live="polite">Loading dialog...</div>}>
        <Dialog isOpen={state.isDialogOpen} onClose={handleDialogClose} article={state.selectedArticle} />
      </Suspense>
    </div>
  );
};

export default React.memo(MapComponent);